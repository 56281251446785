import Home from "./Home";
import {Contacts} from "./contacts";
import {OrderReseller} from "./orderReseller";
import {About} from "./about";
import {OrderCustom} from "./orderCustom";
import {NavHashLink} from "react-router-hash-link";
import i18next, {t} from "i18next";
import "./app.scss";
import {Component} from "react";
import {Footer} from "./footer";

export class App extends Component {
    constructor(props, context) {
        super(props, context);
        this._handleScroll = this._handleScroll.bind(this);
        this._updateStyle = this._updateStyle.bind(this);
        this.hashes = ["home", "about", "order_reseller", "contacts"];
    }

    _handleScroll() {
        let sections = document.querySelectorAll("#app>.content>div>div");
        let fragment = "";
        let distance = 1E10;
        sections.forEach(v => {
            let h;
            if ((h = Math.abs(v.getBoundingClientRect().y)) < distance) {
                distance = h;
                fragment = v.id;
            }
        })
        if (window.location.hash.substring(1) !== fragment) {
            window.history.pushState("", "", window.location.href.split("#")[0] + "#" + fragment)
            this._updateStyle(fragment);
        }
    }

    _updateStyle(hash) {
        document.querySelector("#nav>div").style.setProperty("--index", 2 - this.hashes.indexOf(hash))
        let links = this.hashes.map(x => document.getElementById("nav"+x));
        links.forEach(x => x.classList.toggle("active", x.id === "nav"+hash));
    }

    componentDidMount() {
        let id = window.location.hash.substring(1);
        if (id.length < 2) {
            id = this.hashes[0];
            window.location.hash = id;
        }
        this._updateStyle(id);
        document.getElementById(id).scrollIntoView({behavior: "smooth"});
    }

    render() {
        return <div id={"app"}>
            <div id={"nav"}>
                <div uk-scrollspy="cls: uk-animation-slide-left; target: > a; delay: 100">
                    {this.hashes.map((h, i) => {
                        return <NavHashLink key={h} smooth id={"nav"+h}
                                            hrefLang={i18next.language}
                                            to={"#" + h}
                                            onClick={() => this._updateStyle(h)}
                        >{t("menu." + h)}</NavHashLink>
                    })}
                </div>
            </div>
            <div className={"content"} onWheel={this._handleScroll} onTouchMove={this._handleScroll}>
                <div>
                    <Home/>
                    <About/>
                    {/*<OrderCustom/>*/}
                    <OrderReseller/>
                    <Contacts/>
                    <Footer/>
                </div>
            </div>
        </div>;
    }
}
