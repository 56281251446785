import {t} from "i18next";
import {Trans} from "react-i18next";
import "./footer.scss";

export function Footer() {
    return <div id={"footer"}>
        <p>&#169; {t("footer.copyright")}</p>
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <p><Trans i18nKey={"footer.by"} components={[(<a href={"https://noelnemeth.com"}/>)]} /></p>
    </div>;
}
