import "./section.scss";
import {t} from "i18next";
import "./about.scss";

export function About() {
    return <div id={"about"} className={"section"}>
        <h3 uk-scrollspy={"uk-animation-slide-left-small"}>{t("menu.about")}</h3>
        <div uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > p; delay: 150">
            {t("about_body").split("|").map((v, i) => <p key={i}>{v}</p>)}
        </div>
    </div>;
}
