import "./section.scss";
import {t} from "i18next";
import "./contacts.scss";

export function Contacts() {
    return <div id={"contacts"} className={"section"}>
        <h3 uk-scrollspy={"uk-animation-slide-left-small"}>{t("menu.contacts")}</h3>
        <table uk-scrollspy={"uk-animation-fade"}>
            <tbody>
            <tr>
                <th>
                    {t('name.td')}
                </th>
                <td>
                    <a href={"mailto:d.takats@glassis.eu"}>d.takats@glassis.eu</a><br/>
                    <a href={"tel:+36702609422"}>+36 70 260 9422</a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>;
}
