import "./section.scss";
import {t} from "i18next";
import catalog from "./assets/catalog.pdf";

export function OrderReseller() {
    const listScrollSpy = "cls: uk-animation-slide-bottom-small; target: > li; delay: 150";
    const cardTitleScrollSpy = "cls: uk-animation-slide-left-small";
    return <div id={"order_reseller"} className={"section"}>
        <h3 uk-scrollspy={"uk-animation-slide-left-small"}>{t("menu.order_reseller")}</h3>

        <div uk-grid={""} className={"uk-child-width-1-3@m uk-child-width-1-1@s"}>
            <div>
                <div className="uk-card uk-card-body">
                    <h4 uk-scrollspy={cardTitleScrollSpy} className="uk-card-title uk-text-bold">{t("or.custom.title")}</h4>
                    <ul uk-scrollspy={listScrollSpy}>
                        <li>{t("or.custom.list.1")}</li>
                        <li>{t("or.custom.list.2")}</li>
                        <li>{t("or.custom.list.3")}</li>
                    </ul>
                </div>
            </div>
            <div>
                <div className="uk-card uk-card-body">
                    <h4 uk-scrollspy={cardTitleScrollSpy} className="uk-card-title uk-text-bold">{t("or.classic.title")}</h4>
                    <ul uk-scrollspy={listScrollSpy}>
                        <li>{t("or.classic.list.1")}</li>
                        <li>{t("or.classic.list.2")}</li>
                        <li>{t("or.classic.list.3")}</li>
                        <li>{t("or.classic.list.4")}</li>
                        <li>{t("or.classic.list.5")}</li>
                        <li>{t("or.classic.list.6")}</li>
                        <li>{t("or.classic.list.7")}</li>
                        <li>{t("or.classic.list.8")}</li>
                        <li>{t("or.classic.list.10")}</li>
                        <li>{t("or.classic.list.11")}</li>
                    </ul>
                </div>
            </div>
            <div>
                <div className="uk-card uk-card-body">
                    <h4 uk-scrollspy={cardTitleScrollSpy} className="uk-card-title uk-text-bold">{t("or.sets.title")}</h4>
                    <ul uk-scrollspy={listScrollSpy}>
                        <li>{t("or.sets.list.1")}</li>
                        <li>{t("or.sets.list.2")}</li>
                        <li>{t("or.sets.list.3")}</li>
                    </ul>
                </div>
            </div>
            <a href={catalog} uk-scrollspy={"uk-animation-slide-top-small"} download={t("catalog.file")} className="uk-button uk-align-center uk-button-secondary uk-width-1-2 uk-margin-small-bottom">{t("catalog.btn")}</a>
        </div>
    </div>;
}
