import "./section.scss";
import './Home.scss';
import logo from "./assets/logo.webp";

function Home() {
  return (
    <div id="home" className={"section"}>
        <div>
            <img uk-scrollspy="cls:uk-animation-fade" src={logo}/>
        </div>
    </div>
  );
}

export default Home;
