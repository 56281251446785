import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import i18next, {t} from "i18next";
import I18NextHttpBackend from "i18next-http-backend";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
// noinspection ES6CheckImport
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Helmet} from "react-helmet";
import {NotFound} from "./notFound";
import {App} from "./app";
import {initReactI18next} from "react-i18next";
import "uikit/dist/css/uikit.min.css";
import "uikit/dist/js/uikit.min";

i18next
    .use(I18NextHttpBackend)
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        defaultNS: "default",
        supportedLngs: ["hu", "en"],
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json"
        },
        detection: {
            order: ["path", "navigator"],
            lookupFromPathIndex: 0
        },
        fallbackLng: "en",
        ns: ["default"]
    }).then(() => {
    if (!window.location.pathname.startsWith(`/${i18next.language}`)) {
        window.history.pushState("", "",`/${i18next.language}${window.location.pathname}`);
    }
    const routes = [
        <Route path={""} element={<App />} />
    ]
    ReactDOM.render(
        <React.StrictMode>
            <BrowserRouter>
                <Helmet>
                    <html lang={i18next.language} />
                    <meta name={"description"} content={t("site.description")}/>
                    <title>{t("site.title")}</title>
                </Helmet>
                <Routes>
                    {i18next.languages.map(lng => <Route path={lng} key={lng}>{routes}</Route> )}
                    <Route path={"*"} element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </React.StrictMode>,
        document.getElementById('root')
    );
})
